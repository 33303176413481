import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common2/Modal';
import ModalHeader from '../../../common2/Modal/ModalHeader';
import ModalContent from '../../../common2/Modal/ModalContent';
import ModalFooter from '../../../common2/Modal/ModalFooter';
import RichLocale from '../../../common2/RichLocale';
import Button from '../../../common2/Button/Button';
import LinkIcon from '../../../../icons/link.svg';
import Column from '../../../common/layout/Column';
import Heading3 from '../../../common/Heading3';
import Text from '../../../common/Text';
import Locale from '../../../Translate/Locale';

import './DORSigningLinkModal.scss';

function DORSigningLinkModal(props) {
  const { show, onClose, signingLink, isError } = props;

  const getSigningLinkText = () => {
    if (signingLink) {
      return signingLink;
    }

    if (isError) {
      return 'Error retrieving signing link';
    }

    return 'Loading...';
  };

  return (
    <Modal show={show} onClose={onClose}>
      <ModalHeader icon={LinkIcon} intent="info">
        DOR signing link created
      </ModalHeader>
      <ModalContent>
        <Column>
          <RichLocale ink>DOR_SIGNING_LINK_MESSAGE</RichLocale>
          <div styleName="container">
            <Heading3>DOR Signing Link</Heading3>
            <Text>
              <Locale>Copy and send to your customer</Locale>
            </Text>
            <div styleName="signing-link">{getSigningLinkText()}</div>
          </div>
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button intent="primary" onClick={onClose}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
}

DORSigningLinkModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  signingLink: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default React.memo(DORSigningLinkModal);
